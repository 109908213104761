import {html, LitElement} from "lit-element";
import {LinkContainerStyles} from "./link-container-styles";
//import {SweetAlerts}

export class LinkContainer extends LitElement{
    constructor() {
        super();

        this.srcArray = [];
    }

    static get properties(){
        return {
            srcArray: {Type: Array}
        }
    }

    static get styles(){
        return LinkContainerStyles;
    }

    isExternalURL(route) {
        return route.includes("https://") || route.includes(".") || route.includes(":")
    }

    linkWrapperClicked(route){
        if (this.isExternalURL(route)) {
            window.open(route);
            window.focus();
        } else {
            const customEvent = new CustomEvent("link-wrapper-clicked", {
                "detail": {route: route}
            })
            this.dispatchEvent(customEvent);
        }
    }

    render(){return html`
        <div class="outerDiv">
            <div class="contentWrapper">
                <div class="content">
                    ${this.srcArray.map((entry)=>html`
                        <div class="innerWrapper">
                            <div class="headerWrapper">
                                <div class="header">${entry.headerValue}</div>
                            </div>
                            <div class="textWrapper">
                                <div class="text">${entry.textValue}</div>
                            </div>
                        </div>
                        <div class="linkOuterWrapper">
                            <div @click="${()=> this.linkWrapperClicked(entry.onClickRoute)}" class="linkWrapper">
                                <div class="linkText">${entry["linkValue"]}</div>
                                <img class="arrow" src="https://cdn0.iconfinder.com/data/icons/feather/96/591276-arrow-right-512.png" alt="">
                            </div>
                        </div>
                    `)}
                </div>
            </div>
        </div>
    `}


}customElements.define("link-container", LinkContainer)