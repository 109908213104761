<template>
  <div>
    <landing-header @about-us-selected="goToAboutUs" :landingHeader="landingHeader" :landingDescription="landingDescription"
                    :landingImg="landingImg"></landing-header>
    <text-icon :srcArray="textIconData"></text-icon>
    <primary-text :primaryTextHeader="primaryTextHeader" :primaryTextDescription="primaryTextDescription" ></primary-text>
    <hover-cards @card-clicked="this.hoverCardClicked" :srcArray="hoverCardData" :isCarousel=true></hover-cards>
    <info-icons @info-icon-clicked="this.infoIconClicked" :srcArray="infoIconsData"></info-icons>
    <div style="padding: 80px 20px 80px 20px; background-color: var(--lightgrey);">
      <two-button @btn-one-clicked="goToContact" @btn-two-clicked="downloadInfoMaterial" :contentDescription="contentDescription" :buttonLabelOne="buttonLabelOne" :buttonLabelTwo="buttonLabelTwo"></two-button>
    </div>
    <div style="padding: 40px 20px 60px 20px">
      <row-elements :srcArray="rowElementData"></row-elements>
    </div>
    <div style="background-color: var(--lightgrey);">
      <customer-reviews :srcArray="customerReviewsData"></customer-reviews>
    </div>
    <link-container @link-wrapper-clicked="linkWrapperClicked" :srcArray="linkContainerData"></link-container>
    <news-cards :mainCardSrcArray="mainCardSrcArray" :smallCardSrcArray="smallCardSrcArray"></news-cards>
  </div>
</template>

<script>

import "@/components/lit-element/primary-text/primary-text";
import "@/components/lit-element/hover-cards/hover-cards";
import "@/components/lit-element/landing-header/landing-header";
import {LandingHeaderStrings} from "/src/components/lit-element/landing-header/landing-header-strings";
import LandingImage from "/src/assets/images/titleimg_187730041.jpeg"

import "/src/components/lit-element/text-icon/text-icon"
import iconCases from "/src/assets/icons/text-icons/Experten_Vektorgrafik.svg"
import iconBuilding from "/src/assets/icons/text-icons/Partnerunternehmen_Vektorgrafik.svg"
import iconClock from "/src/assets/icons/text-icons/Firmenhistorie_Vektorgrafik.svg"
import iconThumbUp from "/src/assets/icons/text-icons/Einsatz_Vektorgrafik.svg"
import {PrimaryTextStrings} from "@/components/lit-element/primary-text/primary-text-strings";

import "/src/components/lit-element/info-icons/info-icons"
import iconWaterDrop from "/src/assets/icons/water_drop_black_24dp.svg"
import iconPsychology from "/src/assets/icons/psychology_black_24dp.svg"
import iconConstruction from "/src/assets/icons/construction_black_24dp.svg"
import priceCheckIcon from "/src/assets/icons/price_check_black_24dp.svg"
import manufacturingIcon from "/src/assets/icons/precision_manufacturing_black_24dp.svg"

import "/src/components/lit-element/two-button/two-button"
import {TwoButtonStrings} from "@/components/lit-element/two-button/two-button-strings";

//row-elements
import "/src/components/lit-element/row-elements/row-elements";
import fraesenSliderImg from "/src/assets/images/technologien/fraesen/fraesen-slider-page.jpeg";
import drehenSliderImg from "/src/assets/images/technologien/drehen/drehen_243656939.jpeg"
import honenSliderImg from "/src/assets/images/technologien/honen/honen-slider-img.png"
import komplettbearbeitungSliderImg from "/src/assets/images/technologien/komplettbearbeitung/komplettbearbeitung_60579196.jpeg"
import richtenSliderImg from "/src/assets/images/technologien/richten/richten_55336551.jpeg"
import schleifenSliderImg from "/src/assets/images/technologien/schleifen/schleifen_224478365.jpeg"
import superfinishenSliderImg from "/src/assets/images/technologien/superfinishen/superfinishen_159190324.jpeg"
import verzahnenSliderImg from "/src/assets/images/technologien/verzahnen/verzahnen-224478365.jpeg"

import "/src/components/lit-element/team-member/team-member-popup/team-member-popup"

import "/src/components/lit-element/customer-reviews/customer-reviews"
import logoGrob from "/src/assets/images/partner/logos/grob-logo.png";
import logoWfl from "/src/assets/images/partner/logos/wfl-logo.png";
import logoMae from "/src/assets/images/partner/logos/mae-logo.png";

import "/src/components/lit-element/link-container/link-container"

import "/src/components/lit-element/news-cards/news-cards"
import expertiseImg from "/src/assets/images/irs/team/groupimages/teamwork.jpg";
import SweetAlerts from "../mixins/sweet-alerts/SweetAlerts";


export default {
  name: "Home.vue",
  mixins: [SweetAlerts],
  data(){
    return{
      landingHeader: LandingHeaderStrings.sliderHeaderText1(),
      landingDescription: LandingHeaderStrings.sliderText1(),
      landingImg: LandingImage,

      textIconData: [
          {
            textIconImg:iconCases,
            textIconHeader: "10",
            textIconDescription: "Experten",
          },
          {
            textIconImg:iconBuilding,
            textIconHeader: "12",
            textIconDescription: "Partner-Unternehmen",
          },
          {
            textIconImg: iconClock,
            textIconHeader: "55 Jahre",
            textIconDescription: "Firmenhistorie",
          },
          {
            textIconImg: iconThumbUp,
            textIconHeader: "100%",
            textIconDescription: "im Einsatz",
          }
      ],

      primaryTextHeader: PrimaryTextStrings.primaryTextHeader(),
      primaryTextDescription: PrimaryTextStrings.primaryTextDescription(),

      hoverCardData: [
        {
          imgSrc:fraesenSliderImg,
          imgAlt: "Alt text",
          headerText: "Fräsen",
          descriptionText: "Sytemlösungen Fräs - Bearbeitungszentren",
          onClickRoute: "/technologies/fraesen"
        },
        {
          imgSrc:verzahnenSliderImg,
          imgAlt: "Alt text",
          headerText: "Verzahnen",
          descriptionText: "Vom Wälzfräsen, über das Wälzschälen bis zum Schleifen und Honen oder alternativ Räumen",
          onClickRoute: "/technologies/verzahnen"
        },
        {
          imgSrc:komplettbearbeitungSliderImg,
          imgAlt: "Alt text",
          headerText: "Komplettbearbeitung",
          descriptionText: "Einmal spannen - komplett bearbeiten",
          onClickRoute: "/technologies/komplettbearbeitung"
        },
        {
          imgSrc:superfinishenSliderImg,
          imgAlt: "Alt text",
          headerText: "Superfinishen",
          descriptionText: "Höchste Oberflächenqualitäten und Prozesstabilität",
          onClickRoute: "/technologies/superfinishen"
        },
        {
          imgSrc:drehenSliderImg,
          imgAlt: "Alt text",
          headerText: "Drehen",
          descriptionText: "Vertikal-Drehmaschinen mit Anwendungsvielfalt",
          onClickRoute: "/technologies/drehen"
        },
        {
          imgSrc:honenSliderImg,
          imgAlt: "Alt text",
          headerText: "Honen",
          descriptionText: "Von der Klein- bis zur Großserie",
          onClickRoute: "/technologies/honen"
        },
        {
          imgSrc:richtenSliderImg,
          imgAlt: "Alt text",
          headerText: "Richten",
          descriptionText: "Manuell und automatisch gesteuerte Richtanlagen",
          onClickRoute: "/technologies/richten"
        },
        {
          imgSrc:schleifenSliderImg,
          imgAlt: "Alt text",
          headerText: "Schleifen",
          descriptionText: "Präzise Schleifmaschinen für kleine, mittlere und große Serien",
          onClickRoute: "/technologies/schleifen"
        },
      ],

      infoIconsData: [
        {
          imgSrc: iconWaterDrop,
          textSrc: "Reinigen",
          onClickRoute: "/technologies/reinigen"
        },
        {
          imgSrc: iconPsychology,
          textSrc: "Automatisieren",
          onClickRoute: "/technologies/automatisieren"
        },
        {
          imgSrc: iconConstruction,
          textSrc: "Montieren",
          onClickRoute: "/technologies/montieren"
        },
      ],

      contentDescription: TwoButtonStrings.contentDescription(),
      buttonLabelOne: TwoButtonStrings.buttonLabelOne(),
      buttonLabelTwo: TwoButtonStrings.buttonLabelTwo(),

      rowElementData: [
        {
          imgSrc: manufacturingIcon,
          headerValue: "Technologie- & Maschinen Auswahl",
          textValue: "Für die Entwicklung und den Aufbau von Produktionsanlagen zur individuellen und effizienten Fertigung Ihres anspruchsvollen Produkts verstehen wir uns als kompetenter, professioneller Ansprechpartner – von der technischen Beratung bis zur vollständigen Umsetzung.",
          popupContent:
              {
                popupIcon: manufacturingIcon,
                popupHeaderValue: "Technologie- & Maschinen Auswahl",
                popupTextValue1: "Durch das Abdecken der kompletten Prozesskette in der Zerspanung, der Automation und auch der Montagetechnik, ist es uns möglich Sie gesamtheitlich zu beraten. Gemeinsam mit unseren Lieferwerken, die u.a. Technologie- und Weltmarkführer sind, erarbeiten wir für Sie das effizienteste und effektivste Produktionsverfahren.",
                popupTextValue2: "Von der ersten Werkstücksichtung, über die Prozessauslegung bis zur Maschinenausstattung - wir begleiten Sie durch den gesamten Beschaffungsprozess.",
              },
        },
        {
          imgSrc: priceCheckIcon,
          headerValue: "Kostensenkung durch Verkürzen von Prozessketten",
          textValue: "Im Bereich der Prozesstechnologie prüfen wir in Ihrem Auftrag Fertigungsprozesse für komplexe Bauteile und optimieren die bereits bestehenden Prozesse: Von der Verfahrensanalyse über Testbearbeitungen und Beratung bis hin zur praktischen Umsetzung unterstützen wir Sie ganzheitlich.",
          popupContent:
              {
                popupIcon: priceCheckIcon,
                popupHeaderValue: "Kostensenkung durch Verkürzen von Prozessketten",
                popupTextValue1: "Im Bereich der Prozesstechnologie prüfen wir in Ihrem Auftrag Fertigungsprozesse für komplexe Bauteile und optimieren die bereits bestehenden Prozesse: Von der Verfahrensanalyse über Testbearbeitungen und Beratung bis hin zur praktischen Umsetzung unterstützen wir Sie ganzheitlich.",
                popupTextValue2: "Von der Ersatzteilversorgung, über Wartungs- und Reparaturservices bis zu Nachrüstungen & Modernisierungen: Wir stehen gerne als Ihr kompetenter Ansprechpartner zur Verfügung.",
              },
        },
        {
          imgSrc: iconConstruction,
          headerValue: "Unterstützung Ihrer Wartungs- und Instandhaltungsprozesse",
          textValue: "Von der Ersatzteilversorgung, über Wartungs- und Reparaturservices bis zu Nachrüstungen & Modernisierungen: Wir stehen gerne als Ihr kompetenter Ansprechpartner zur Verfügung.",
          popupContent:
              {
                popupIcon: iconConstruction,
                popupHeaderValue: "Unterstützung Ihrer Wartungs- und Instandhaltungsprozesse",
                popupTextValue1: "Von der Ersatzteilversorgung, über Wartungs- und Reparaturservices bis zu Nachrüstungen & Modernisierungen: Wir stehen gerne als Ihr kompetenter Ansprechpartner zur Verfügung.",
              },
        },
      ],

      customerReviewsData: [
        {
          imgSrc: logoGrob,
          imgAlt: "Logo der GROB Werke GmbH & Co. KG",
          companyName: "GROB Werke GmbH & Co. KG",
          companyDescription: "GROB bietet der Serienfertigung modernste Systemlösungen in Form von individualisierten Fertigungsanlagen in Zerspanung und Montage.",
        },
        {
          imgSrc: logoWfl,
          imgAlt: "Logo der WFL MILLTURN TECHNOLOGIES GMBH & CO.KG",
          companyName: "WFL MILLTURN TECHNOLOGIES GMBH & CO.KG",
          companyDescription: "Als weltweit einziger Hersteller konzentriert sich WFL ausschließlich auf die Herstellung multifunktionaler Dreh-Bohr-Fräszentren.",
        },
        {
          imgSrc: logoMae,
          imgAlt: "Logo der Maschinen und Apparatebau Götzen GmbH",
          companyName: "Maschinen und Apparatebau Götzen GmbH",
          companyDescription: "MAE ist führend in den Produktbereichen „Richten“, „Hydraulikpressen“ und „Fügen“.",
        },
      ],

      linkContainerData: [
        {
          headerValue: "Weiterführende Informationen",
          textValue: "Mit innovativen Technologien und Lösungen helfen wir Ihnen Zeit und Geld zu sparen. Die Erfahrung unserer Vertriebsingenieure und die vielseitigen Technologien unserer Lieferwerke sichern die Effizienz und Qualität Ihrer Produktion.",
          onClickRoute: "/about-us/partner",
          linkValue: "Zu den Lieferwerken",
        },
        {
          onClickRoute: "/about-us/team",
          linkValue: "Zu Ihren Ansprechparntnern",
        },
      ],

      mainCardSrcArray: [
        {
          linkSrc: "https://www.linkedin.com/feed/update/urn:li:activity:6846750800971202560",
          imgSrc: expertiseImg,
          headerValue: "Welche Expertise zeichnet uns aus?",
          dateValue: "Aug 10, 2021",
          descriptionValue: "Welche Expertise zeichnet uns aus und was stimmt uns auch in diesen besonderen Zeiten als Unternehmen optimistisch? Neben den richtigen Partnern sind dies an erster Stelle unsere Mitarbeiter:innen."

        },
      ],
      smallCardSrcArray: [
        {
          linkSrc: "https://www.linkedin.com/posts/irs-werkzeugmaschinen-gmbh_einfach-mal-wieder-in-angenehmer-atmosph%C3%A4re-activity-6925376376652890112-DrKn?utm_source=linkedin_share&utm_medium=member_desktop_web",
          headerValue: "Einfach mal wieder in angenehmer Atmosphäre austauschen?",
          dateValue: "Apr 28, 2022",
          descriptionValue: "Dann lassen Sie uns doch auf der GRINDINGHUB 2022 vom 17.05. – 20.05. zu einem Schleif- & Superfinish-Expertengespräch zusammenkommen."
        },
        {
          linkSrc: "https://www.linkedin.com/feed/update/urn:li:activity:6859795445795954688 ",
          headerValue: "WFL - einmal spannen, komplett bearbeiten",
          dateValue: "Nov 29, 2021",
          descriptionValue: "Sie suchen nach einer Möglichkeit Ihre Bauteile komplett zu bearbeiten - ohne mehrmaliges Umspannen und sind dabei auf höchste Produktivität, Flexibilität und Präzision angewiesen? Dann sollten wir uns unterhalten."
        },
        {
          linkSrc: "https://www.linkedin.com/feed/update/urn:li:activity:6872567225346355201",
          headerValue: "Richten... Aber richtig!",
          dateValue: "Nov 16, 2021",
          descriptionValue: "Wenn es um das Richten von runden, profilierten sowie räumlich geformten Bauteilen geht und hohe Produktivität bei gleichbleibender hoher Qualität benötigt wird, führt kein Weg an der automatischen Richtmaschine M-AH unseres Partners MAE. Maschinen- und Apparatebau Götzen GmbH vorbei."
        },
      ],
    }
    },
    methods:{
      goToAboutUs(){
        this.$router.push({
          path: "/about-us",
        });
        window.scrollTo(0, 0);
      },
      goToContact(){
        this.$router.push({
          path: "/about-us/contact",
        })
        window.scrollTo(0, 0);
      },
      downloadInfoMaterial(){
        this.showConfirmDialogue("Dokument", "Infomaterial herunterladen (PDF)?").then(()=>{
            const tab = window.open("https://public-storage-irs.s3.eu-central-1.amazonaws.com/IRS_02_2022.pdf");
            tab.focus();
        })
      },
      hoverCardClicked(event){
        const route = event.detail.route;

        this.$router.push({
          "path": route
        })
        window.scrollTo(0, 0);
      },
      infoIconClicked(event){
        const route = event.detail.route;

        this.$router.push({
          "path": route
        })
        window.scrollTo(0, 0);
      },
      linkWrapperClicked(event){
        const route = event.detail.route;

        this.$router.push({
          "path": route
        })
        window.scrollTo(0, 0);
      },
    }
}
</script>

<style scoped>

</style>
