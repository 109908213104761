import {css} from "lit-element"

export const RowElementsStyles = css`

  .background{
    position: fixed;
    background-color: black;
    width: 0;
    height: 0;
    left: 0;
    top: 50px;
    opacity: 0;
  }

  .showBg {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .content{
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  
  .innerWrapper{
    width: 300px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: fit-content;
    min-height: 250px;
    margin: 20px;
  }
  
  .headerWrapper{
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .overHeader{
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .imgWrapper{
    margin-bottom: 20px;
    height: fit-content;
  }
  
  .show-more-btn-container{
    display: flex;
    justify-content: center;
  }
  
  .showMore{
    color: var(--primary-color);
    text-decoration: underline;
  }
  
  .showMore:hover{
    opacity:0.5;
    cursor: pointer;
  }

  @media only screen and (max-width: 1050px) {
    
    .innerWrapper{
      width: auto;
      margin: auto;
    }
    
    .content{
      display: block;
    }
  }
  

  

`