<template>
  <div class="container">
    <div class="mobile-header">
      <div @click="this.navigate('/')" style="color:white;">
        <span>IRS Werkzeugmaschinen</span>
      </div>
      <span class="mobile-quick-action-contact" @click="this.navigate('/about-us/contact')">
          <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 0 24 24" width="32px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M0 0h24v24H0z" fill="none"/><path d="M21 8V7l-3 2-3-2v1l3 2 3-2zm1-5H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm8-6h-8V6h8v6z"/></svg>
      </span>
      <div @click="this.isOpen = !this.isOpen" class="menu-button">
        <span v-if="!isOpen" >
          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
        </span>
        <span v-if="isOpen">
          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/></svg>
        </span>
      </div>
      <transition name="nested" :duration="550">
        <div v-if="isOpen" class="nav-content-container outer">
          <div class="inner flex">
            <span class="main-tab" @click="this.navigate('/')">{{home}}</span>
            <span class="main-tab" @click="this.navigate('/about-us')">{{aboutUs}}</span>
            <div class="sub-nav">
              <span @click="this.navigate('/about-us/team')">{{ this.team }}</span>
              <span @click="this.navigate('/about-us/career')">{{ this.career }}</span>
              <span @click="this.navigate('/about-us/partner')">{{ this.partner }}</span>
              <span @click="this.navigate('/about-us/contact')">{{this.contact}}</span>
            </div>
            <span class="main-tab" style="border-top: 1px solid var(--lightgrey)">{{ technologies }}</span>
            <div class="sub-nav">
              <span @click="this.navigate('/technologies/automatisieren')">{{this.automatisieren}}</span>
              <span @click="this.navigate('/technologies/drehen')">{{this.drehen}}</span>
              <span @click="this.navigate('/technologies/fraesen')">{{ this.fraesen }}</span>
              <span @click="this.navigate('/technologies/honen')">{{this.honen}}</span>
              <span @click="this.navigate('/technologies/komplettbearbeitung')">{{ this.komplettbearbeitung }}</span>
              <span @click="this.navigate('/technologies/montieren')">{{this.montieren}}</span>
              <span @click="this.navigate('/technologies/reinigen')">{{this.reinigen}}</span>
              <span @click="this.navigate('/technologies/richten')">{{this.richten}}</span>
              <span @click="this.navigate('/technologies/schleifen')">{{this.schleifen}}</span>
              <span @click="this.navigate('/technologies/superfinishen')">{{this.superfinishen}}</span>
              <span @click="this.navigate('/technologies/verzahnen')">{{ this.verzahnen }}</span>
            </div>
            <span class="main-tab" @click="this.navigate('/consulting')">{{ consulting }}</span>
            <span class="main-tab" @click="this.navigate('/news')">{{ news }}</span>
            <span class="main-tab"></span>
          </div>
          <footer-bar></footer-bar>
        </div>
      </transition>

    </div>
  </div>

</template>

<script>
import {NavigationBarLocales} from "../../../locales/navigation-bar/navigation-bar-locales";
import FooterBar from "../footer-bar/FooterBar";

export default {
  name: "MobileNavBar",
  components:{FooterBar},
  data(){
    return{
      isOpen: false,

      home: NavigationBarLocales.home(),
      aboutUs: NavigationBarLocales.aboutUs(),
      contact: NavigationBarLocales.contact(),
      partner:NavigationBarLocales.partner(),
      career: NavigationBarLocales.career(),
      team: NavigationBarLocales.team(),
      technologies: NavigationBarLocales.technologies(),
      fraesen: NavigationBarLocales.fraesen(),
      komplettbearbeitung: NavigationBarLocales.komplettbearbeitung(),
      verzahnen: NavigationBarLocales.verzahnen(),
      superfinishen: NavigationBarLocales.superfinishen(),
      drehen: NavigationBarLocales.drehen(),
      honen: NavigationBarLocales.honen(),
      schleifen: NavigationBarLocales.schleifen(),
      richten: NavigationBarLocales.richten(),
      automatisieren: NavigationBarLocales.automatisieren(),
      montieren: NavigationBarLocales.montieren(),
      reinigen: NavigationBarLocales.reinigen(),
      consulting: NavigationBarLocales.consulting(),
      news: NavigationBarLocales.news(),
    }
  },
  methods:{
    // navigate to a view, by providing the path.
    // each path needs to be registered inside the router
    // component first.
    navigate(extension){
      this.$router.push({
        path: extension,
      })
      this.isOpen = false;
    },
  }
}

</script>

<style scoped>

.mobile-quick-action-contact{
  position: absolute;
  right: 75px;
  top: 9px;
  width: 32px;
  height: 32px;
}

.flex{
  display: flex;
  flex-direction: column;
}

.sub-nav{
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.nav-content-container .sub-nav span{
  padding: 20px;
  font-size: 16px;
}

.nav-content-container .main-tab{
  padding: 20px;
  font-size: 18px;
  border-bottom: 1px solid var(--lightgrey);
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0;
}

/* delay enter of nested element for staggered effect */
.nested-enter-active .inner {
  transition-delay: 0.25s;
}
  .mobile-header{
    height: 50px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--darkgrey);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid white;
    z-index: 5000000;
  }

  body{
    overflow: hidden;
  }

  .nav-content-container{
    position: absolute;
    left: 0;
    top: 50px;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    text-align: left;
    overflow: scroll;
  }

  .menu-button span{
    display: flex;
  }

</style>
