
export class FooterBarStrings{

    static subscribedToNewsLetterText(){
        return "Vielen Dank - wir haben Sie erfolgreich zum Newsletter angemeldet!";
    }

    static subscribedToNewsLetterErrorText(){
        return "Bitte prüfen Sie Ihre Email Adresse - das Format scheint inkorrekt zu sein.";
    }

    static errorTitle(){
        return "Fehler";
    }

    static successTitle(){
        return "Erfolg";
    }
}

