import {css} from "lit-element";

export const TextIconStyles = css`

  .outerDiv{
    padding: 40px 0;
    background-color: var(--primary-color);
  }
  
  .contentWrapper{
    display: flex;
    justify-content: space-evenly;
    max-width: 960px;
    height: fit-content;
    flex-wrap: wrap;
    margin: auto;
  }

  .rowOne{
    width: 80%;
    display: flex;
    justify-content: space-around;
  }

  .itemOuterDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .itemHeader{
    font-weight: bold;
  }
  
  .itemImageDiv{
    height: 80px;
  }
  
  .itemImage{
    height: 100%;
  }

  .itemTextDiv{
    width: fit-content;
    text-align: center;
    color: white;
  }

  @media only screen and (max-width: 1050px) {
    .contentWrapper{
      display: grid;
      grid-template-columns: auto auto;
    }
    
    .itemOuterDiv{
      margin-right: 15px;
    }
    
    .itemOuterDiv:last-child{
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 720px) {
    
  }

  @media only screen and (max-width: 480px) {
    .contentWrapper{
      grid-template-columns: auto;
    }
  }
    
`