import {css} from "lit-element"

export const PrimaryTextStyles = css`

  .outerDiv{
    max-width: 720px;
    margin: auto auto auto auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }

  .contentWrapper{
    text-align: center;
    margin: 80px auto;
    width: 960px;
  }

  .itemHeader{
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 480px) {
    .contentWrapper{
      margin: 40px auto;
    }

    .itemHeader{
      margin-bottom: 20px;
      font-size: 20px;
    }

    .itemText{
        font-size: 14px;
    }
  }
`