import {css} from "lit-element";

export const TeamMemberPopupStyles = css`
  
  .contentWrapper{
    width: 800px;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 2px;
    z-index: 2;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 3px grey;
  }
  
  .memberName{
    margin-right: 12px;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .memberJob{
    font-size: .875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .memberJob svg{
    margin-right: 3px;
  }
  
  .memberDescription{
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .content{
    display: flex;
    align-items: flex-start;
    padding: 40px;
  }
  
  .contact a{
    color: var(--primary-color);
    text-decoration: none;
  }
  
  .contact:hover{
    text-decoration: underline;
  }
  
  .closeButton{
    position: absolute;
    right: 0;
    top: 0;
  }

  .closeButton:hover{
    opacity: 0.5;
    cursor: pointer;
  }
  
  .memberImage{
    height: 100%;
    width: 100%;
  }
  
  .contentLeft{
    max-width: 50%;
  }

  .contentRight{
    margin-left: 40px;
    z-index: 1;
    text-align: left;
  }

  @media only screen and (max-width: 840px) {
    .contentWrapper{
      top: 50px;
      left: 0;
      width: 100%;
      transform: none;
    }
  }

  @media only screen and (max-width: 720px) {
    .content{
      flex-direction: column-reverse;
      padding: 20px;
    }

    .memberImage{
      min-width: auto;
    }

    .contentRight{
      margin-left: 0;
    }
  }

 
  
  
`