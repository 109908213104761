
import {HoverCardsStyles} from "./hover-cards-styles";
import {html, LitElement} from "lit-element";


export class HoverCards extends LitElement{
    constructor() {
        super();

        this.srcArray = [];
        this.isCarousel = false;
        this.pos = window.screen.availWidth > 1050 ? 1 : 0;
        this.counter = 1;
        this.activeArray = []
        this.timer = this.setTimer()
        this.sectionOne = [];
        this.sectionTwo = [];
        this.sectionThree = [];
        this.isChanged = false;
        this.min = 1
        this.max = 3

    }

    static get properties(){
        return {
            srcArray: {Type: Array},
            isCarousel: {Type: Boolean},
            pos: {Type: Number},
            posLeft: {Type: Number},
            active: {Type: Boolean},
            section1:{Type: Array},
            section2:{Type: Array},
            section3:{Type: Array},
            counter: {Type: Number},
            activeArray: {Type: Array},
            isChanged: {Type: Boolean},
            time: {Type: Number},
            min: {Type: Number},
            max: {Type: Number},
        }
    }

    static get styles(){
        return HoverCardsStyles
    }

    createSmallArrays(){
        if(window.screen.availWidth > 1050){
            switch (this.pos){
                case 1:
                    this.activeArray = this.sectionOne
                    break
                case 2:
                    this.activeArray = this.sectionTwo
                    break
                case 3:
                    this.activeArray = this.sectionThree
                    break
            }

            for(let card of this.srcArray){
                if(this.counter <=3 && this.counter > 0){
                    this.sectionOne.push(card)
                    this.counter++
                }else if(this.counter >=4 && this.counter <=6){
                    this.sectionTwo.push(card)
                    this.counter++
                }else if(this.counter >= 7 && this.counter <=9){
                    this.sectionThree.push(card)
                    this.counter++
                }else{
                    break
                }
            }
        }else{
            if(window.screen.availWidth < 1050){
                this.max = this.srcArray.length - 1
            }
            if(window.screen.availWidth < 1050){
                this.min = 0
            }
        }
    }

    setTimer(){
        return setInterval(()=>{
            if(this.isCarousel === true){
                this.pos = this.pos + 1
                if(this.pos > this.max){
                    this.pos = this.min
                }
            }
        }, 10000);
    }

    moveRight(){
        clearInterval(this.timer)
        this.timer = this.setTimer();
        this.pos = this.pos + 1
        if(this.pos > this.max){
            this.pos = this.min
        }
    }

    moveLeft(){
        clearInterval(this.timer)
        this.timer = this.setTimer();
        this.pos = this.pos - 1
        if(this.pos < this.min){
            this.pos = this.max
        }
    }

    changeProgress(number){
        this.pos = number
        clearInterval(this.timer)
        this.timer = this.setTimer();
    }


    startCarousellFunctions(){
        this.createSmallArrays();
        this.timer.start;
    }

    isExternalURL(route) {
        return route.includes("https://") || route.includes(".") || route.includes(":")
    }

    cardClicked(route){
        if (this.isExternalURL(route)) {
            window.open(route);
            window.focus();
        } else {
            const customEvent = new CustomEvent("card-clicked", {
            "detail": {route: route}
        })
        this.dispatchEvent(customEvent);
        }
    }


    //TODO:: only show one card when window with is < 1050px &
    //TODO:: (only show one bar as well)
    render() {
        return html`
            <div class="outerDiv">
                <div class="contentWrapper">
                    ${this.isCarousel ? this.startCarousellFunctions():null}
                    ${this.isCarousel ? html`
                        <div class="buttonWrapper">
                            <span class="arrow" @click="${()=>this.moveLeft()}">
                                <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 0 24 24" width="80px" fill="#555555FF"><path d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                                </svg>
                            </span>
                        </div>
                    ${window.screen.availWidth > 1050 ? this.activeArray.map((entry)=>html`
                        <div @click="${()=> this.cardClicked(entry.onClickRoute)}" class="cards" id="contentCards">
                                <div class="imgWrapper">
                                    <img class="img" src="${entry.imgSrc}" alt="${entry.imgAlt}">
                                </div>
                                <div class="headerWrapper">
                                    <div class="header">${entry.headerText}</div>
                                    ${entry["descriptionText"] ? html `
                                        <span class="description">${entry.descriptionText}</span>
                                    ` : null}
                                </div>
                                <div class="showMore">Ansehen</div>
                        </div>
                    `) :html`
                        <div class="cards" id="contentCards">
                                <div class="imgWrapper">
                                    <img class="img" src="${this.srcArray[this.pos]["imgSrc"]}" alt="">
                                </div>
                                <div class="headerWrapper">
                                    <div class="header">${this.srcArray[this.pos]["headerText"]}</div>
                                    ${this.srcArray[this.pos]["descriptionText"] ? html `
                                        <span class="description">${this.srcArray[this.pos]["descriptionText"]}</span>
                                    ` : null}
                                </div>
                                <div class="showMore">Ansehen</div>
                        </div>
                    `}
                    ${this.isCarousel ? html`
                        <div class="buttonWrapper">
                            <span class="arrow"  @click="${()=>this.moveRight()}">
                                <svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 0 24 24" width="80px" fill="#555555FF"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
                            </span>
                        </div>
                    ` : null}
                        <div class="progressWrapper">
                            <div class="innerWrapper">
                                ${window.screen.availWidth > 1050 ? html`
                                    <div id="bar1" class="bar ${this.pos === 1 ? "active" : null}" @click="${()=>this.changeProgress(1)}"></div>
                                    <div id="bar2" class="bar ${this.pos === 2 ? "active" : null}" @click="${()=>this.changeProgress(2)}"></div>
                                    <div id="bar3" class="bar ${this.pos === 3 ? "active" : null}" @click="${()=>this.changeProgress(3)}"></div>
                                ` : null}
                            </div>
                        </div>
                    ` : html`
                        ${this.srcArray.map((entry)=>html`
                        <div @click="${()=> this.cardClicked(entry.onClickRoute)}" class="cards" id="contentCards">
                                <div class="imgWrapper">
                                    <img class="img" src="${entry.imgSrc}" alt="${entry.imgAlt}">
                                </div>
                                <div class="headerWrapper">
                                    <div class="header">${entry.headerText}</div>
                                    ${entry.descriptionText ? html `
                                        <span class="description">${entry.descriptionText}</span>
                                    ` : null}
                                </div>
                                <div class="showMore">Ansehen</div>
                        </div>
                        `)}`}
                </div>
            </div>
            </div>
        `
    }
}
customElements.define("hover-cards", HoverCards);
