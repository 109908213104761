import {css} from "lit-element";

export const LinkContainerStyles = css`
  
  .outerDiv{
    max-width:960px;
    margin: 80px auto;
  }
  
  .contentWrapper{
    text-align: center;
  }

  .headerWrapper{
    margin-top:40px;
    margin-bottom:40px;
    text-align: center;
  }
  
  .innerWrapper{
    padding: 0 20px;
  }

  .arrow{
    height: 30px;
  }
  
  .linkWrapper{
    border-bottom: 3px solid #8C5454;
    padding: 0 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .linkWrapper:hover{
    cursor: pointer;
  }
  
  .linkText{
    text-align: left;
  }
  
  .linkOuterWrapper{
    margin-top: 20px;
  }
  
  .header{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
  }
`