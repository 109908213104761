import {html, LitElement} from "lit-element";
import {TeamMemberPopupStyles} from "./team-member-popup-styles";

export class TeamMemberPopup extends LitElement{
    constructor() {
        super();
        this.srcArray = []
    }

    static get styles(){
        return TeamMemberPopupStyles
    }

    static get properties(){
        return{
            srcArray: {Type: Array},
        }
    }

    closeButtonClicked(){
        const customEvent = new CustomEvent("close-button-clicked",{})
        this.dispatchEvent(customEvent)
    }


    render(){
        return html`
            <div class="outerDiv">
                <div class="contentWrapper">
                    ${this.srcArray.name ? html`
                    <div class="content">
                        <div class="contentLeft">
                            <img class="memberImage" src="${this.srcArray.img}" alt="">
                        </div>
                        <div class="contentRight">
                            <div class="closeButton" @click="${() => this.closeButtonClicked()}"}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                            </div>
                            <div class="memberName">${this.srcArray.name}</div>
                            <div class="memberJob">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#BE2428"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"/></svg>
                                </span>
                                <span>${this.srcArray.job}</span>
                            </div>
                            <div class="memberDescription"> ${this.srcArray.description}</div>
                            <div class="contact"> <a href="tel:${this.srcArray.phone}">${this.srcArray.phone}</a></div>
                            <div class="contact"> <a href="mailto:${this.srcArray.email}">${this.srcArray.email}</a></div>
                        </div>
                    </div>
                    `: html`
                        <!--if the popup is used outside of TeamMember, the content below is rendered-->
                        <div class="content">
                            <div class="contentLeft">
                                <img class="memberImage" src="${this.srcArray.popupIcon}">
                            </div>
                            <div class="contentRight">
                                <div class="closeButton" @click="${() => this.closeButtonClicked()}"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                                </div>
                                <div class="memberName">${this.srcArray.popupHeaderValue}</div>
                                
                                <div class="memberDescription"> ${this.srcArray.popupTextValue1}</div>
                                <div class="memberDescription"> ${this.srcArray.popupTextValue2}</div>
                            </div>
                    </div>
                    `}
                </div>
            </div>
        `}
}
customElements.define("team-member-popup", TeamMemberPopup)