
import {InfoIconsStyles} from "./info-icons-styles";
import {html, LitElement} from "lit-element";


export class InfoIcons extends LitElement{
    constructor() {
        super();
        this.srcArray = [];
    }

    static get properties(){
        return {
            srcArray: {Type: Array}
        }
    }

    static get styles(){
        return InfoIconsStyles
    }

    infoIconClicked(route){
        const customEvent = new CustomEvent("info-icon-clicked", {
            "detail": {route: route}
        })

        this.dispatchEvent(customEvent);
    }

    render() {
        return html`
        <div class="outerDiv">
            <div class="contentWrapper">
                <div class="header"></div>
                <div class="innerContentWrapper">
                    ${this.srcArray.map((entry)=> html`
                        
                            <div @click="${()=> this.infoIconClicked(entry.onClickRoute)}" class="content">
                                <div class="imgWrapper">
                                    <img class="img" src="${entry.imgSrc}" alt="">
                                </div>
                                <div class="textWrapper">
                                    <div class="text">${entry.textSrc}</div>
                                </div>
                            </div>
                        
                    `)}
                </div>
            </div>
        </div>
        </div>
    `}

}
customElements.define("info-icons", InfoIcons);