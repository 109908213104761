<template>
  <div class="outerDivTwo">
    <div class="rowOne">
      <div class="contentWrapperOne">
        <div class="contentRowOne">
          <div class="newsletterWrapper">
            <div class="newsletter">
              <svg class="mail-icon" xmlns="http://www.w3.org/2000/svg" height="24px"
                   viewBox="0 0 24 24" width="24px" fill="#ffffff">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/>
              </svg>
              <div class="newsletterText">Newsletter abonnieren</div>
            </div>
          </div>
          <div class="subscribeWrapper">
            <div>
              <input :value="email" @change="(event)=> this.email = event.target.value"
                     class="emailField" type="email" id="email" name="email" placeholder="Ihre E-Mail Adresse"/>
              <button @click="()=>this.subscribeToNewsletter()" class="subscribeButton" type="button">Abonnieren</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rowTwo">
      <div class="contentWrapperTwo">
        <div class="contentList">
          <div class="header">Technologien</div>
          <a @click="this.goToRoute('/technologies/fraesen')" class="text">Fräsen</a>
          <a @click="this.goToRoute('/technologies/verzahnen')" class="text">Verzahnen</a>
          <a @click="this.goToRoute('/technologies/komlettbearbeitung')" class="text">Komplettbearbeitung</a>
          <a @click="this.goToRoute('/technologies/superfinishen')" class="text">Superfinishen</a>
          <a @click="this.goToRoute('/technologies/drehen')" class="text">Drehen</a>
          <a @click="this.goToRoute('/technologies/honen')" class="text">Honen</a>
          <a @click="this.goToRoute('/technologies/richten')" class="text">Richten</a>
          <a @click="this.goToRoute('/technologies/schleifen')" class="text">Schleifen</a>
          <a @click="this.goToRoute('/technologies/automatisieren')" class="text">Automatisieren</a>
          <a @click="this.goToRoute('/technologies/montieren')" class="text">Montieren</a>
          <a @click="this.goToRoute('/technologies/reinigen')" class="text">Reinigen</a>
        </div>
        <div class="contentList">
          <a @click="this.goToRoute('/consulting')" class="header">Beratung</a>
          <a @click="this.goToRoute('/consulting')" class="text">Technologie- & Maschinen Auswahl</a>
          <a @click="this.goToRoute('/consulting')" class="text">Prozessketten</a>
          <a @click="this.goToRoute('/consulting')" class="text">Wartungs- & Instandhaltungsprozesse</a>
        </div>
        <div class="contentList">
          <a @click="this.goToRoute('/about-us')" class="header">Über IRS Werkzeugmaschinen</a>
          <a @click="this.goToRoute('/about-us/team')" class="text">Team</a>
          <a @click="this.goToRoute('/about-us/career')" class="text">Karriere</a>
          <a @click="this.goToRoute('/news')" class="text">News</a>
          <a @click="this.goToRoute('/about-us/partner')" class="text">Partner & Lieferwerke</a>
        </div>
        <div class="contentList">
          <a @click="this.goToRoute('/technologies/verzahnen')" class="header">Kontakt</a>
          <div class="text adress">IRS Werkzeugmaschinen GmbH</div>
          <div class="text adress">Gartenstraße 19</div>
          <div class="text adress">D-71272 Renningen</div>
          <div class="text adress">
            <a class="text adress" href="tel:+491234567890">+49 7159 8059200</a>
          </div>
          <div class="text adress">
            <a class="text adress" href="mailto:info@irs-gmbh.de">info@irs-gmbh.de</a>
          </div>
          <div class="iconDiv">
            <a class="icon-label-wrapper" href="https://www.linkedin.com/company/irs-werkzeugmaschinen-gmbh">
              <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.5375 3H19.5563C20.3766 3 21 3.59063 20.9953 4.40156V19.4531C20.9953 20.2641 20.3766 20.9953 19.5516 20.9953H4.5375C3.71719 20.9953 3 20.2687 3 19.4531V4.40156C3 3.59063 3.71719 3 4.5375 3ZM7.35938 8.76562H7.37812C8.23594 8.76562 8.77031 8.15156 8.77031 7.38281C8.75156 6.59531 8.23594 6 7.39219 6C6.54844 6 6 6.6 6 7.38281C6 8.15156 6.53437 8.76562 7.35938 8.76562ZM6 18H8.57812V9.98438H6V18ZM18 18H15.4219V13.6172C15.4219 12.5672 15.0469 11.85 14.1141 11.85C13.4016 11.85 12.9797 12.3328 12.7922 12.8016C12.7219 12.9703 12.7031 13.2 12.7031 13.4344V18H10.125V9.98438H12.7031V11.1C13.0781 10.5656 13.6641 9.79688 15.0281 9.79688C16.7203 9.79688 18 10.9125 18 13.3172V18Z"
                      fill="#ffffff"></path>
              </svg>
              <span class="iconLabel">LinkedIn</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="rowThree">
      <div class="contentDivOne">
        <a @click="this.goToRoute('/data-privacy')" class="text small">Datenschutz</a>
        <a @click="this.goToRoute('/legal-notice')" class="text small">Impressum</a>
        <a class="text small" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&hl=de">Cookie-Einstellungen</a>
      </div>
    </div>
  </div>
</template>

<script>

import SweetAlerts from "../../../mixins/sweet-alerts/SweetAlerts";
import {HttpsRequests} from "../../../mixins/https-requests";
import {FooterBarStrings} from "../../../locales/footer-bar/footer-bar-strings";

export default {
  name: "FooterBar",
  mixins: [SweetAlerts],
  data(){
    return{
      email: ""
    }
  },
  methods:{

    goToRoute(route){
      this.$router.push({
        "path": route
      })
    },

    isEmailValid(email){
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    subscribeToNewsletter(){
      if(this.isEmailValid(this.email)){

        const payload = {
          email: this.email
        };

        HttpsRequests.postRequest("subscribeToNewsletter", payload).then(() => {
          this.email = "";

          this.showSuccessMessage(FooterBarStrings.successTitle(), FooterBarStrings.subscribedToNewsLetterText());
        })

        } else {
          this.showErrorMessage(FooterBarStrings.errorTitle(), FooterBarStrings.subscribedToNewsLetterErrorText());
      }
    }
  }
}
</script>

<style scoped>

.newsletterWrapper {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.newsletter {
  display: flex;
  align-items: center;
}

.contentRowOne{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  max-width: 720px;
  margin: auto;
}

.contentList{
  text-align: left;
}

.newsletterText{
  font-weight: bold;
  color: white;
  margin-left: 5px;
}

.rowTwo {
  width: 100%;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.rowThree {
  padding: 20px 0;
  display: flex;
  justify-content: space-evenly;
}

.emailField {
  background-color: transparent;
  color: white;
  border: 1px solid darkgrey;
  border-radius: 5px;
  height: 40px;
  width: 300px;
  padding: 10px;
  margin-right:20px;
  box-sizing: border-box;
}

.subscribeButton {
  width: 150px;
  height: 40px;
  background-color: #8c5454;
  border-radius: 5px;
  border: 3px solid #8c5454;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.2s;
}

.subscribeButton:hover {
  opacity: 0.5;
  transition: opacity 0.2s;
  cursor: pointer;
}

.contentWrapperTwo {
  display: flex;
  justify-content: space-between;
  max-width: 1110px;
  margin: auto;
  padding: 40px 20px;
}

.header {
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
  text-decoration: none;
}

a{
  text-decoration: none;
}

a:hover{
  color: var(--primary-color);
  cursor: pointer;
}

.icon-label-wrapper:hover span{
  color: var(--primary-color);
}

.icon-label-wrapper:hover svg path{
  fill: var(--primary-color);
}

.text {
  margin-top: 15px;
  color: rgba(245, 245, 245, .8);
  text-decoration: none;
  display: block;
}


.contentDivOne {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contentDivOne .small{
  margin-right: 20px;
}

.contentDivOne .small:last-child{
  margin-right: 0;
}

.iconDiv {
  margin-top: 20px;
}

.icon-label-wrapper{
  display: flex;
  align-items: center;
}

.iconLabel{
  color: rgba(245, 245, 245, 0.8);;
  text-decoration: none;

}

.mail-icon{
  width:48px;
  height:48px;
}

.outerDivTwo{
  background-color: var(--darkgrey);
}

@media only screen and (max-width: 1050px) {
  .contentWrapperTwo{
    display: grid;
    grid-template-columns: auto auto;
    gap: 40px;
  }
}

@media only screen and (max-width: 720px) {
  .contentWrapperTwo{
    display: flex;
    flex-direction: column;
  }

  .contentRowOne{
    display: block;
  }

  .newsletter{
    margin-bottom: 10px;
  }

  .emailField {
    width: 100%;
    margin-bottom: 20px;
  }

  .newsletterWrapper {
    width:auto;
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (max-width: 455px) {
  .contentDivOne {
    display: block;
    justify-content: left;
    align-items: revert;
  }

  .contentDivOne .small{
    text-align: left;
  }

  .rowThree{
    justify-content: left;
    padding: 20px;
  }

  .subscribeButton {
    width: 100%;
  }
}


</style>
