import {css} from "lit-element";

export const customerReviewsStyles = css`
    
  .outerDiv{
    max-width: 1110px;
    margin: auto;
    padding: 40px 0 60px 0;
  }
  
  .contentWrapper{
    display: flex;
    justify-content: space-between;
  }

  .content{
    margin: 20px;
    max-width: 300px;
  }
  
  .customerImageWrapper{
    display: flex;
    align-items: center;
    height: 80px;
  }
  
  .customerImage{
    max-width: 150px;
  }
  
.innerWrapper{
  text-align: left;
}
  
  .rowTwo{
    display: flex;
    justify-content: space-around;
  }

  .customerTextWrapper{
    max-width: 100%;
    min-height: 100px;
    height: fit-content;
    padding-top: 10px;
    border-top: 3px solid #8C5454;
  }

  .customerText{
    max-width: 100%;
    //max-height: 100%;
    min-height: 100px;
  }
  
  .customerNameWrapper{
    margin-bottom: 10px;
  }
  
  .customerName{
    font-weight: bolder;
  }
  
  .customerCompany{
    font-weight: lighter;
  }

  @media only screen and (max-width: 1050px) {
    
    .outerDiv{
      padding: 10px 20px;
    }
    
    .contentWrapper{
      display: block;
    }

    .content{
      max-width: none;
      margin: 0 0 20px 0;
    }

    .customerNameWrapper{
      margin-top: 10px;
    }

    .customerTextWrapper, .customerText{
      min-height: auto;
    }
  }

  
  
  
  
  
`