
export class NavigationBarLocales{

    static home(){
        return "Home";
    }

    static aboutUs(){
        return "Über uns";
    }
    static team(){
        return "Team";
    }
    static contact(){
        return "Kontakt";
    }
    static career(){
        return "Karriere";
    }
    static partner(){
        return "Lieferwerke";
    }

    static technologies(){
        return "Technologien";
    }
    static fraesen(){
        return "Fräsen";
    }
    static verzahnen(){
        return "Verzahnen";
    }
    static komplettbearbeitung(){
        return "Komplettbearbeiten";
    }
    static superfinishen(){
        return "Superfinishen";
    }
    static drehen(){
        return "Drehen";
    }
    static honen(){
        return "Honen";
    }
    static richten(){
        return "Richten";
    }
    static schleifen(){
        return "Schleifen";
    }
    static automatisieren(){
        return "Automatisieren";
    }
    static montieren(){
        return "Montieren";
    }
    static reinigen(){
        return "Reinigen";
    }

    static consulting(){
        return "Beratung";
    }

    static news(){
        return "News";
    }


}