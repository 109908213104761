
export class TwoButtonStrings{

    static contentDescription(){
        return "Hier können Sie sich die wichtigsten Infos zu IRS Werkzeugmaschinen abspeichern oder direkt eine Kontaktanfrage stellen. Wir freuen uns auf Sie!"
    }

    static buttonLabelOne(){
        return "Kontaktanfrage stellen";
    }

    static buttonLabelTwo(){
        return "Infomaterial herunterladen";
    }

}