import {createRouter, createWebHistory} from "vue-router";
import Home from "/src/views/Home";

const routes = [
    //Fallback route (404) -> if page is not found route to homepage
    {
        path: '/:pathMatch(.*)',
        component: Home
    },
    {
        path: "/",
        component: Home
        },
    {
        path: "",
        component: Home
    },
    {
          path: "/home",
          component: Home
    },
    {
        path: "/about-us",
        component: () => import(/* webpackChunkName: "about" */ "/src/views/AboutUs")
    },
    {
        path: "/about-us/contact",
        component: () => import(/* webpackChunkName: "contact" */ "/src/views/Contact")
    },
    {
        path: "/about-us/partner",
        component: () => import(/* webpackChunkName: "partner" */ "/src/views/Partner")
    },
    {
        path: "/about-us/career",
        component: () => import(/* webpackChunkName: "career" */ "/src/views/Career")
    },
    {
        path: "/about-us/team",
        component: () => import(/* webpackChunkName: "team" */ "/src/views/Team")
    },
    {
        path: "/technologies/fraesen",
        component: () => import(/* webpackChunkName: "fraesen" */ "/src/views/technologies/Fraesen")
    },
    {
        path: "/technologies/verzahnen",
        component: () => import(/* webpackChunkName: "verzahnen" */ "/src/views/technologies/Verzahnen")
    },
    {
        path: "/technologies/komplettbearbeitung",
        component: () => import(/* webpackChunkName: "komplettbearbeitung" */ "/src/views/technologies/Komplettbearbeitung")
    },
    {
        path: "/technologies/superfinishen",
        component: () => import(/* webpackChunkName: "superfinishen" */ "/src/views/technologies/Superfinishen")
    },
    {
        path: "/technologies/drehen",
        component: () => import(/* webpackChunkName: "drehen" */ "/src/views/technologies/Drehen")
    },
    {
        path: "/technologies/honen",
        component: () => import(/* webpackChunkName: "honen" */ "/src/views/technologies/Honen")
    },
    {
        path: "/technologies/richten",
        component: () => import(/* webpackChunkName: "richten" */ "/src/views/technologies/Richten")
    },
    {
        path: "/technologies/schleifen",
        component: () => import(/* webpackChunkName: "schleifen" */"/src/views/technologies/Schleifen")
    },
    {
        path: "/technologies/reinigen",
        component: () => import(/* webpackChunkName: "reinigen" */"/src/views/technologies/Reinigen")
    },
    {
        path: "/technologies/automatisieren",
        component: () => import(/* webpackChunkName: "automatisieren" */"/src/views/technologies/Automatisieren")
    },
    {
        path: "/technologies/montieren",
        component: () => import(/* webpackChunkName: "montieren" */"/src/views/technologies/Montieren")
    },
    {
        path: "/consulting",
        component: () => import(/* webpackChunkName: "consulting" */"/src/views/Consulting")
    },
    {
        path: "/news",
        component: () => import(/* webpackChunkName: "news" */"/src/views/News")
    },
    {
        path: "/data-privacy",
        component: () => import(/* webpackChunkName: "data-privacy" */"/src/views/small/DataPrivacy")
    },
    {
        path: "/legal-notice",
        component: () => import(/* webpackChunkName: "legal-notice" */"/src/views/small/LegalNotice")
    },
    {
        path: "/kontakt-speichern/fabian-raetzsch",
        component: () => import(/* webpackChunkName: "fabian-raetzsch" */"/src/views/digitalCallingCards/fabianRaetzsch")
    },
    {
        path: "/kontakt-speichern/thomas-eschwey",
        component: () => import(/* webpackChunkName: "thomas-eschwey" */"/src/views/digitalCallingCards/thomasEschwey")
    },
    {
        path: "/kontakt-speichern/eric-hoess",
        component: () => import(/* webpackChunkName: "eric-hoess" */"/src/views/digitalCallingCards/ericHoess")
    },
    {
        path: "/kontakt-speichern/tino-guerrini",
        component: () => import(/* webpackChunkName: "tino-guerrini" */"/src/views/digitalCallingCards/tinoGuerrini")
    },
    {
        path: "/kontakt-speichern/stefan-raetzsch",
        component: () => import(/* webpackChunkName: stefan-raetzsch" */"/src/views/digitalCallingCards/stefanRaetzsch")
    },
    {
        path: "/kontakt-speichern/frank-raetzsch",
        component: () => import(/* webpackChunkName: frank-raetzsch" */"/src/views/digitalCallingCards/frankRaetzsch")
    },

]


//When using createWebHistory(), the URL will
// look "normal," https://example.com/user/id (without hashtag)
const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(() => {
    window.scrollTo(0, 0)
})

export default router
