import {css} from "lit-element";

export const LandingHeaderStyles = css`
  
  .outerDiv{
    padding-top: 50px;
  }
  
  .headline-content{
    color:#555;
    font-size: 40px;
  }
  
  .slider{
    height: 450px;
    width:100%;
    display:flex;
    justify-content: left;
    align-items: center;
    background-color: white;
    margin: auto;
    background-repeat: no-repeat; 
    background-size: cover; 
    background-position: right;
  }

  .slider-text-wrapper{
    height: 100%;
    max-width: 600px;
    //background-image: linear-gradient(to right, #D9D9D9 , #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0 40px;
    background: rgba(56,56,56,.8);
    text-align: left;
  }

  .slider-text {
    color: #fff;
    font-size: 16px;
    line-height: 25px;
  }

  .slider-header-text{
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    padding: 24px 0;
  }
  
  .slider-button-wrapper{
    padding: 24px;
  }
  
  .slider-button{
    max-height: 50px;
    min-height: 30px;
    width: 180px;
    background-color: transparent;
    color: #fff;
    border: 3px solid var(--primary-color);
    border-radius: 5px;
  }
  
  .slider-button:hover{
    border: 3px solid #A6A6A6;
    cursor: pointer;
  }

  //mobile

  @media only screen and (max-width: 1050px) {
    .outerDiv{
      padding: 0;
    }
    
    .slider{
      background-position: revert;
    }
  }

  @media only screen and (max-width: 600px) {
    .slider-text-wrapper{
      padding: 0 20px;
    }

    .slider-text-wrapper{
      align-items: flex-start;
    }

    .slider-button-wrapper{
      padding: 0;
      margin-top: 24px;
    }
  }

  @media only screen and (max-width: 480px) {
      .slider-header-text{
        font-size: 25px;
      }

    .slider-text {
      font-size: 14px;
    }
  }



  
`
