import {css} from "lit-element"

export const HoverCardsStyles = css`

  a {
    color: black;
    text-decoration: none;
  }

  .cards {
    padding: 3px;
    background-color: var(--lightgrey);
    width: 200px;
    max-width: 200px;
    min-height: 370px;
    border-radius: 5px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .15);
    transition: all .2s ease-in-out;
  }

  .cards:hover {
    transform: scale(1.1);
    cursor: pointer;
  }


  .outerDiv {
    display: flex;
    justify-content: space-around;
    height: fit-content;
  }

  .imgWrapper {
    background-color: white;
    padding: 20px;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    max-height: 152px;
    margin: auto;
    width: auto;
    max-width: 150px;
    text-align: center;
    display: flex;
  }

  .header {
    font-weight: bold;
    margin-top: 10px;
    color: black;
  }

  .headerWrapper {
    text-align: center;
    background-color: var(--lightgrey);
    display: flex;
    flex-direction: column;
    padding: 7px;
  }

  .contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    max-width: 1150px;
    flex-wrap: wrap;
  }

  .showMore {
    right: 10px;
    bottom: 10px;
    color: var(--primary-color);
    font-weight: 100;
    font-size: 14px;
  }

  .description {
    margin-top: 10px;
    text-align: center;
    font-weight: 100;
    font-size: 12px;
    color: black;
  }

  .arrow:hover {
    cursor: pointer;
  }

  .progressWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .innerWrapper {
    width: 400px;
    display: flex;
    justify-content: space-around;
  }

  .bar {
    width: 120px;
    height: 5px;
    border: 1px solid lightgray;
    background: linear-gradient(to right, #BE2428 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
  }

  .arrow svg{
    height: 60px;
    width: 60px;
  }

  .bar:hover {
    cursor: pointer;
  }

  .active{
    animation-duration: 10s;
    animation-name: progress;
  }
  

  @keyframes progress {
    from {
      background-position: right bottom;
    }

    to {
      background-position: left bottom;
    }
  }

  @media only screen and (max-width: 420px) {
    .contentWrapper{
      gap: 0;
      margin-bottom: 40px;
    }

    .innerWrapper{
      width: auto;
    }
    
    .cards{
      margin-bottom: 20px;
    }
    
  }
  



`
