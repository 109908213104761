<template>
  <div class="top-menu">
    <div class="top-menu-wrapper">
        <div @click="this.navigate('/home')" class="top-menu-logo">
          <img :src="irsLogo" alt="">
        </div>
        <div class="flex-container">
            <div class="navigation-container" @click="this.navigate('/home')">
              <span>{{home}}</span>
            </div>
            <div @mouseleave="this.triggerSubNavigation(false, 'aboutUs')"
                 @mouseenter="this.triggerSubNavigation(true, 'aboutUs')"
                 class="navigation-container">
              <span @click="this.navigate('/about-us')">{{aboutUs}}</span>
              <div v-if="this.showAboutUsSubNav" class="sub-navigation-container">
                <span @click="this.navigate('/about-us/team')">{{ this.team }}</span>
                <span @click="this.navigate('/about-us/career')">{{ this.career }}</span>
                <span @click="this.navigate('/about-us/partner')">{{ this.partner }}</span>
                <span @click="this.navigate('/about-us/contact')">{{this.contact}}</span>
              </div>
            </div>
            <div @mouseleave="this.triggerSubNavigation(false, 'technologies')"
                 @mouseenter="this.triggerSubNavigation(true, 'technologies')"
                 class="navigation-container">
              <span>{{ technologies }}</span>
              <div v-if="this.showTechnologiesSubNav" class="sub-navigation-container">
                <span @click="this.navigate('/technologies/automatisieren')">{{this.automatisieren}}</span>
                <span @click="this.navigate('/technologies/drehen')">{{this.drehen}}</span>
                <span @click="this.navigate('/technologies/fraesen')">{{ this.fraesen }}</span>
                <span @click="this.navigate('/technologies/honen')">{{this.honen}}</span>
                <span @click="this.navigate('/technologies/komplettbearbeitung')">{{ this.komplettbearbeitung }}</span>
                <span @click="this.navigate('/technologies/montieren')">{{this.montieren}}</span>
                <span @click="this.navigate('/technologies/reinigen')">{{this.reinigen}}</span>
                <span @click="this.navigate('/technologies/richten')">{{this.richten}}</span>
                <span @click="this.navigate('/technologies/schleifen')">{{this.schleifen}}</span>
                <span @click="this.navigate('/technologies/superfinishen')">{{this.superfinishen}}</span>
                <span @click="this.navigate('/technologies/verzahnen')">{{ this.verzahnen }}</span>
              </div>
            </div>
            <div class="navigation-container">
              <span @click="this.navigate('/consulting')">{{ consulting }}</span>
            </div>
            <div class="navigation-container">
              <span @click="this.navigate('/news')">{{ news }}</span>
            </div>
        </div>
      <div>
        <!--  ph to make flex work -->
      </div>
    </div>
  </div>
</template>

<script>

// images
import irsLogo from "/src/assets/logos/Logo-white-IRS.svg";

// strings
import {NavigationBarLocales} from "/src/locales/navigation-bar/navigation-bar-locales";

export default {
  name: "navigation-bar",
  data(){
    return{
      irsLogo: irsLogo,

      home: NavigationBarLocales.home(),
      aboutUs: NavigationBarLocales.aboutUs(),
        contact: NavigationBarLocales.contact(),
        partner:NavigationBarLocales.partner(),
        career: NavigationBarLocales.career(),
        team: NavigationBarLocales.team(),
      technologies: NavigationBarLocales.technologies(),
        fraesen: NavigationBarLocales.fraesen(),
        komplettbearbeitung: NavigationBarLocales.komplettbearbeitung(),
        verzahnen: NavigationBarLocales.verzahnen(),
        superfinishen: NavigationBarLocales.superfinishen(),
        drehen: NavigationBarLocales.drehen(),
        honen: NavigationBarLocales.honen(),
        schleifen: NavigationBarLocales.schleifen(),
        richten: NavigationBarLocales.richten(),
        automatisieren: NavigationBarLocales.automatisieren(),
        montieren: NavigationBarLocales.montieren(),
        reinigen: NavigationBarLocales.reinigen(),
      consulting: NavigationBarLocales.consulting(),
      news: NavigationBarLocales.news(),

      showAboutUsSubNav: false,
      showTechnologiesSubNav: false,
    }
  },

  methods:{
    // navigate to a view, by providing the path.
    // each path needs to be registered inside the router
    // component first.
    navigate(extension){
      this.$router.push({
        path: extension,
      })
    },

    triggerSubNavigation(shouldShow, navigationKey){
      switch (navigationKey.toLowerCase()){
        case "aboutus":
          this.showAboutUsSubNav = !this.showAboutUsSubNav;
          break;
        case "technologies":
          this.showTechnologiesSubNav = !this.showTechnologiesSubNav;
          break;
      }
    }
  }
}

</script>

<style scoped>

.top-menu{
  padding: 0 20px;
  background-color: var(--darkgrey);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
}

.navigation-container{
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
}

.sub-navigation-container{
  position: absolute;
  display: flex;
  flex-direction: column;
  gap:20px;
  text-align: left;
  background-color: var(--darkgrey);
  padding:20px;
  box-sizing: border-box;
}

.top-menu-wrapper{
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1110px;
  height: 50px;
  margin: 0 auto;
}

.top-menu-logo{
  max-width: 110px;
  display: flex;
}

.top-menu-logo:hover{
  cursor: pointer;
  opacity: 0.8;
}

.top-menu-logo img {
  max-width: 100%;
  height: 40px;
}

.flex-container{
  display:flex;
  justify-content: center;
}

.navigation-container span:hover{
  cursor:pointer;
  opacity: 0.5;
}

</style>
