export class PrimaryTextStrings{

    static primaryTextDescription(){
        return "Unsere langjährige Industrieerfahrung und der direkte Zugang zu führendem Technologie-Wissen im Maschinenbau ermöglichen es uns, Sie bei individuellen Projekten bei vielseitigen Fragestellungen gemeinsam mit unseren Lieferwerken zu unterstützen.";
    }

    static primaryTextHeader(){
        return "Immer das passende Maschinenkonzept für Ihre Bearbeitungsaufgabe";
    }

}