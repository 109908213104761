import {css} from "lit-element";

export const QuickActionSidebarStyles = css`
    .content-wrapper{
      width: 45px;;
      background-color: var(--primary-color);
      z-index: 1;
      position: fixed;
      top: 80px;
      right: 0;
      transition: all .2s ease-in-out;
      border-radius: 3px 0 0 3px;
    }
  
  a{
    text-decoration: none;
  }
  
  .icon svg{
    fill: white;
    width: 36px;
  }
  
  .icon{
    position: relative;
    top: 2px;
  }
  
  .content-wrapper:hover{
    width: 202px;
    transition: all .2s ease-in-out;
cursor: pointer;
  }
  
  .text{
    color: white;
    margin-left: 5px;
  }
  
  .content{
    display: flex;
    align-items: center;
    padding: 5px;
  }
  
  .content:hover .text{
    color: var(--darkgrey);;
  }
  
  .content:hover{
    background-color: lightgrey;
  }

  .content:hover svg{
    fill: var(--darkgrey);
  }
  
  hr{
    margin-block-start: 0;
    margin-block-end: 0;
   
    border-width:0;
    color:var(--darkgrey);
    background-color:white;
  }
`