
export class LandingHeaderStrings{

    static sliderText1(){
        return "Im Rahmen unserer kontinuierlichen Kundenbetreuung und ingenieurtechnischen Beratung, erarbeiten wir mit unseren Kunden und Lieferwerken zukunftsorientierte, effiziente Produktionsverfahren";
    }

    static sliderHeaderText1(){
        return "Ihr kompetenter Partner für Bearbeitungsaufgaben und Automation";
    }

    static sliderShowMore(){
        return "Erfahren Sie mehr";
    }

}