import {css} from "lit-element"

export const TwoButtonStyles = css`
  .outerDivOne{
    display: flex;
    justify-content: space-evenly;
  }

  .buttonContentWrapper{
    width: 600px;
    text-align: center;
  }

  .infoButton1{
    width: 200px;
    height: 40px;
    background-color: #8C5454;
    color: white;
    border-radius: 5px;
    border: 3px solid #8C5454;
    font-weight: bold;
    opacity: 1;
    transition: opacity 0.2s;
  }

  .infoButton1:hover{
    opacity: 0.5;
    transition: opacity 0.2s;
    cursor: pointer;
  }

  .infoButton2{
    width: 200px;
    height: 40px;
    background-color: transparent;
    border-radius: 5px;
    border: 3px solid var(--primary-color);
    font-weight: bold;
    opacity: 1;
    transition: background-color 0.2s;
    margin-left: 40px;
  }
  .infoButton2:hover{
    border: 3px solid #A6A6A6;
    transition: background-color 0.2s;
    cursor: pointer;
  }

  .itemOuterDiv{
    min-width: 300px;
    padding-left: 10px;
    margin-top: 30px;
  }
  

  @media only screen and (max-width: 720px) {
    .itemOuterDiv{
      display: flex;
      flex-direction: column;
      padding-left: 0;
      min-width: auto;
    }

    .infoButton1, .infoButton2{
      width: auto;
      margin-left: 0;
    }

    .infoButton1{
      margin-bottom: 20px;
    }
  }
`