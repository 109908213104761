<template>
  <div id="app">
    <div class="desktop">
      <navigation-bar></navigation-bar>
    </div>
    <div class="mobile">
      <mobile-nav-bar></mobile-nav-bar>
    </div>
    <router-view>
    </router-view>
    <cookie-banner v-if="!isAccepted"></cookie-banner>
    <footer-bar></footer-bar>
    <div class="desktop">
      <quick-action-sidebar @btn-one-clicked="navigate('/about-us/contact')" @btn-two-clicked="navigate('/about-us/team')"></quick-action-sidebar>
    </div>
  </div>
</template>

<script>

import "/src/components/lit-element/quick-action-sidebar/quick-action-sidebar"
import NavigationBar from "/src/components/vue/navigation-bar/navigation-bar";
import FooterBar from "./components/vue/footer-bar/FooterBar";
import MobileNavBar from "./components/vue/navigation-bar/MobileNavBar";
import CookieBanner from "@/components/lit-element/cookie-banner/cookieBanner";


export default {
  name: 'App',
  components: {CookieBanner, MobileNavBar, NavigationBar, FooterBar},
  methods:{
    navigate(route){
      this.$router.push({
        "path": route
      })
    }
  },
  computed:{
    isAccepted(){
      return localStorage.getItem("cookieBannerAccepted");
    }
  },
  created() {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'de')
  }
}

</script>

<style>

body{
  margin:0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
}

:root {
  --primary-color: #BE2428;
  --secondary-color: rgb(151, 150, 150);
  --darkgrey: rgb(29, 29, 29);
  --lightgrey: #f1f2f3;
}

.desktop {
  display: block;
}

.mobile{
  display: none;
}

@media only screen and (max-width: 720px) {
  .desktop {
    display: none;
  }

  .mobile{
    display: block;
  }

}
</style>
