<template>

<div v-if="isBanner" class="outerDiv">
  <div>Diese Webseite nutzt Cookies um Ihre Erfahrung zu verbessern.
  <span class="clickable" style="color: var(--primary-color)" @click="this.linkToCookiePage">Mehr zum Datenschutz</span>
  </div>
  <div>
    <button @click="confirmCookies">Bestätigen</button>
  </div>
</div>
</template>

<script>
export default {
  name: "cookieBanner.vue",
  data(){
    return{
      isBanner: true,
    }
  },
  methods:{
    linkToCookiePage(){
      this.$router.push({
        "path": '/data-privacy'
      })
      window.scrollTo(0, 0);
    },
    confirmCookies(){
      this.isBanner = false;
      localStorage.setItem("cookieBannerAccepted", "yes")
    }
  }
}
</script>

<style scoped>
  .outerDiv{
    background-color: #ced4da;
    color: black;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .outerDiv div{
    margin: .5rem 1rem;
  }

  .clickable:hover{
    cursor:pointer;
    opacity:0.5;
  }

  button{
    max-height: 50px;
    min-height: 30px;
    background-color: transparent;
    color: black;
    border: 3px solid var(--primary-color);
    border-radius: 5px;
    cursor: pointer;
  }

  @media only screen and (max-width: 720px){
    .outerDiv{
      justify-content: space-between;
      text-align: left;
    }
  }
</style>