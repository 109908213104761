import {html, LitElement} from "lit-element";
import {QuickActionSidebarStyles} from "./quick-action-sidebar-styles";
// noinspection ES6UnusedImports
import {QuickActionSidebarStrings} from "./quick-action-sidebar-strings";

export class QuickActionSidebar extends LitElement{

    constructor() {
        super();
    }

    static get styles(){
        return QuickActionSidebarStyles
    }

    dispatchBtnOneClickedEvent(){
        this.dispatchEvent(new CustomEvent("btn-one-clicked"));
    }

    dispatchBtnTwoClickedEvent(){
        this.dispatchEvent(new CustomEvent("btn-two-clicked"));
    }

    render(){
        return html`
            <div class="content-wrapper">
                <div @click="${()=>this.dispatchBtnOneClickedEvent()}" class="content">
                    <span class="icon">
                        <svg class="mailIcon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="white"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"></path></svg>
                    </span>
                    <span class="text">${QuickActionSidebarStrings.contact()}</span>
                </div>
                
                <div @click="${()=>this.dispatchBtnTwoClickedEvent()}" class="content">
                    <span class="icon">
                        <svg class="personIcon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
                    </span>
                    <span class="text">${QuickActionSidebarStrings.responsible()}</span>
                </div>
            </div>
            
        `}
}customElements.define("quick-action-sidebar", QuickActionSidebar)